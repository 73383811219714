@import url('https://fonts.googleapis.com/css2?family=DynaPuff&family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=DynaPuff&family=Kalam&family=Varela+Round&display=swap');
.main {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.homeContainer {

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.textContainer {
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

}
.header {
  font-size: 9vh;
  color:white;
  height: 12%;
  transition: 0.5s;
  font-family: 'DynaPuff';
  font-family: 'Varela Round', sans-serif;
}

.content {
  font-size: 5vh;
  color: white;
  height: 10%;
  
}

.header:hover {
  color: transparent;
  -webkit-text-stroke: 2px white;
}

.backgroundVideo {
  position: absolute; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; 
  background-color: #000;
  
}
.linkedIn {
  font-size: 4vh; 
  color: white;
  margin-right: 10px; 
  transition: 0.3s;  
}
  
.linkedIn:hover {
    color: #005582; 
    transform: scale(1.1); 
}
.logoSection {
  display: flex;
  
}

.gitHub {
  font-size: 4vh; 
  color: white;
  margin-right: 10px; 
  transition: 0.3s;  
}

.gitHub:hover {
  color: #000000; 
  transform: scale(1.1); 
}

.email {
  font-size: 4vh; 
  color: white;
  margin-right: 10px; 
  transition: 0.3s; 
}

.email:hover {
  color: #00bfff; 
  transform: scale(1.1); 
}

.file {
  font-size: 4vh; 
  color: white;
  margin-right: 10px; 
  transition: 0.3s; 
}

.file:hover {
  color: #5a6366; 
    transform: scale(1.1); 
}
body {
  margin: 0;
  padding: 0;
}

.aboutScreen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 720px) {
  .header {
    font-size: 8vh;
    color:white;
    height: 12%;
    transition: 0.5s;
    font-family: 'DynaPuff';
    font-family: 'Varela Round', sans-serif;
  }
}

