@import url('https://fonts.googleapis.com/css2?family=DynaPuff&family=Kalam&family=Varela+Round&display=swap');
body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;  
    
}

.mainContainer { 
    display: flex;
    justify-content: flex-end; 
    align-items: stretch;
}

.buttonContainer a  {
    text-decoration: none;
    color: white;
    font-size: 30px;
    font-weight: bold;
    width: 10%;
    font-family: 'DynaPuff', cursive;
    font-family: 'Kalam', cursive;
    font-family: 'Varela Round', sans-serif;
}

.buttonContainer:hover a {
    color: transparent;
  -webkit-text-stroke: 0.5px rgb(185, 154, 253);
  transition: 0.5s;
  transform: scale(5); 
  font-size: 40px;
  
}

.buttonContainer {
    margin: 0 10px; 
}